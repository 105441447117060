/*eslint-disable */
<template>
  <b-container fluid v-bind:class="$route.query.module_id ? 'event_data student_parent_gide' : 'student_parent_gide'">
    <b-row class="mt-5">
      <b-col lg="12" class="lead_add_form p-o">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="eventDetail.event_img" style="width:100%" />
            <h4 class="card-title mt-1">{{ cvCardTitle }}</h4>
            <!-- SignIn Link -->
            <div class="sign-info col-12" v-if="!showSessions && !eventDetail.event_is_paid">
              <span class="dark-color d-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}"><span style="font-size: 18px;">Sign In</span></router-link></span><hr>
            </div><!-- SignIn Link -->
          </template>
          <template v-slot:body>
            <ValidationObserver ref="pbaForm">
              <form action="#">
                <div class="form-row">
                  <!-- Scroll Bar CSS -->
                  <div v-bind:class = "(operatingsystem == 'web') ? 'gide_scroll_box row m-0' : 'gide_box row m-0' ">
                    <!-- User Type -->
                    <div class="col-md-12 mb-3">
                      <p>{{cvCardSubHeader}}<label class="primary-color font-size-16">*</label></p>
                      <ValidationProvider
                        name="Choice"
                        rules="required"
                        v-slot="{ errors }">
                        <multiselect
                          v-model="vmUsertype"
                          :options="vmStudentParentSelectOptions"
                          track-by="name"
                          label="name"
                          id="validation_parentType"
                          :placeholder="cvStuParTypeLabel"
                          :tag-placeholder="cvStuParTypeLabel"
                          required
                          >
                        </multiselect>
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div><!-- User Type -->

                    <template v-if="vmUsertype.name == 'Student'">
                      <!-- Student Gender -->
                      <div class="col-md-12 mb-3">
                        <label for="validation_gender">Gender</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmUserGender"
                            :options="vmGender"
                            track-by="name"
                            label="name"
                            :placeholder="cvGender"
                            :tag-placeholder="cvGender"
                            id="validation_gender"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Gender -->

                      <!-- Student Name -->
                      <div class="col-md-12 mb-3">
                        <label for="validationfirst_name">{{ cvStuNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="First Name" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_first_name" type="text" class="form-control" id="" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Name -->

                      <!-- Student Last Name -->
                      <div class="col-md-12 mb-3">
                        <label for="validationlast_name">{{ cvStuLastNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_last_name" type="text" class="form-control" id="validationlast_name" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Last Name -->

                      <!-- Student Email -->
                      <div class="col-md-12 mb-3">
                        <label for="validationstu_email">{{ csStuEmailLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_email" type="text" class="form-control" id="validationstu_email" required />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div><!-- Student Email -->

                      <!-- Country Code -->
                      <div class="col-sm-4 col-md-12 col-lg-6 col-12  mb-3">
                        <label for="validation_country">{{cvStuCountryCodeLabel}}</label><label class="primary-color font-size-16">*</label>
                        <div class="d-flex flex-row">
                          <ValidationProvider name="Select One" rules="required" v-slot="{ errors }">
                            <select v-model="vmStuParContactUsFormData.user_country" class="form-control w-100" id="validation_country">
                              <template class="w-100">
                                <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                  {{country.country_name}} (+ {{country.country_phone_code}})
                                </option>
                              </template>
                            </select>
                            <span class="text-danger"> {{ errors[0] }} </span>
                          </ValidationProvider>
                        </div>
                      </div><!-- Country Code -->

                      <!-- Student Contact No -->
                      <div class="col-sm-8 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="validation_stu_contact_no">{{ cvStuContactNoLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Contact Number" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_contact_no" type="tel" class="form-control" id="validation_stu_contact_no" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Contact No -->

                      <!-- Student Grade -->
                      <div class="col-md-12 mb-3">
                        <label for="vmGradePlaceHolder">{{ vmGradePlaceHolder }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuGrade"
                            :options="vmGrade"
                            track-by="name"
                            label="name"
                            :placeholder="vmGradePlaceHolder"
                            :tag-placeholder="vmGradePlaceHolder"
                            id="vmGradePlaceHolder"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Grade -->

                      <!-- School Name -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validation_name">{{ cvStuSchoolNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="School Name" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_sch_name" type="text" class="form-control" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- School Name -->

                      <!-- School Board -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validations_cirrum"> {{ vmStuCurrPlaceHolder }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuCurr"
                            :options="vmCurriculum"
                            track-by="name"
                            label="name"
                            :placeholder="vmStuCurrPlaceHolder"
                            :tag-placeholder="vmStuCurrPlaceHolder"
                            id="validations_cirrum"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- School Board -->

                      <!-- Student Passing Year -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validations_passing_year"> {{ vmStuPassPlaceHolder }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuPassingYear"
                            :options="vmStuPass"
                            track-by="name"
                            label="name"
                            :placeholder="vmStuPassPlaceHolder"
                            :tag-placeholder="vmStuPassPlaceHolder"
                            id="validations_passing_year"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Passing Year -->

                      <!-- Parent Email -->
                      <div class="col-md-12 mb-3">
                        <label for="validationpar_email">{{ cvParEmailLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.parent_email" type="text" class="form-control" id="validationpar_email" required />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div><!-- Parent Email -->

                      <!-- Country Code -->
                      <div class="col-sm-4 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="validationstu_city">{{cvStuCountryCodeLabel}}<label class="primary-color">*</label></label>
                        <div class="d-flex flex-row">
                          <ValidationProvider name="Select One" rules="required" v-slot="{ errors }">
                            <select v-model="vmStuParContactUsFormData.parent_country" class="form-control w-100" id="validationstu_city">
                              <template class="w-100">
                                <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                  {{country.country_name}} (+ {{country.country_phone_code}})
                                </option>
                              </template>
                            </select>
                            <span class="text-danger"> {{ errors[0] }} </span>
                          </ValidationProvider>
                        </div>
                      </div><!-- Country Code -->

                      <!-- Contact No -->
                      <div class="col-sm-8 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="parent_contact_no">{{ cvParContactNoLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Contact Number" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.parent_contact_no" type="tel" class="form-control" id="parent_contact_no" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Contact No -->

                      <!-- Study Country Choice -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="parent_prefer_country">{{ vmStuChoiceCountry }}?</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuPrefferedCountry"
                            :options="vmStuCountryChoice"
                            :multiple="true"
                            track-by="name"
                            label="name"
                            :placeholder="vmStuChoiceCountry"
                            :tag-placeholder="vmStuChoiceCountry"
                            id="parent_prefer_country"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Study Country Choice -->

                      <!-- Program Choice -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validationpcn_name">{{ cvProgramChoiceNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Preferred Program for Undergrad. Studies" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.course_name" type="text" class="form-control" id="validationpcn_name" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Program Choice -->

                      <!-- Country Where You Live -->
                      <div class="col-md-12 mb-3">
                        <label for="validationstu_country">{{ cvStuLive }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Select Country" rules="required" v-slot="{ errors }">
                          <select v-model="stuCountry" class="form-control" id="validationstu_country">
                            <template>
                              <option v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                {{country.country_name}}
                              </option>
                            </template>
                          </select>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Country Where You Live -->

                      <!-- City Where You Live -->
                      <div class="col-md-12 mb-3">
                        <label for="validationusrl_city">{{ cvStuCityLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="City Name" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_city" type="text" class="form-control" id="validationusrl_city" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- City Where You Live -->
                    </template><!-- Student Type -->

                    <!-- Parent Type -->
                    <template v-else>
                      <!-- Student Grade -->
                      <div class="col-md-12 mb-3">
                        <label for="validation_gender">Student Gender</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider ValidationProvider
                          name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmUserGender"
                            :options="vmGender"
                            track-by="name"
                            label="name"
                            :placeholder="cvGender"
                            :tag-placeholder="cvGender"
                            id="validation_gender"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Grade -->

                      <!-- Parent Name -->
                      <div class="col-md-12 mb-3">
                        <label for="validationpfirst_name">{{ cvStuNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="First Name" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.parent_first_name" type="text" class="form-control" id="validationpfirst_name" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Parent Name -->

                      <!-- Parent Last Name -->
                      <div class="col-md-12 mb-3">
                        <label for="validationpsecond_name">{{ cvStuLastNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider
                            name="Last Name"
                            rules="required"
                            v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.parent_last_name" type="text" class="form-control" id="validationpsecond_name" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Parent Last Name -->

                      <!-- Parent Email -->
                      <div class="col-md-12 mb-3">
                        <label for="validationpar_email">{{ vsParEmailLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.parent_email" type="text" class="form-control" id="validationpar_email" required />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div><!-- Parent Email -->

                      <!-- Country Code -->
                      <div class="col-sm-4 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="validationparent_country">{{cvStuCountryCodeLabel}}</label><label class="primary-color font-size-16">*</label>
                        <div class="d-flex flex-row">
                          <ValidationProvider name="Select One" rules="required" v-slot="{ errors }">
                            <select v-model="vmStuParContactUsFormData.parent_country" class="form-control w-100" id="validationparent_country">
                              <template class="w-100">
                                <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                  {{country.country_name}} (+ {{country.country_phone_code}})
                                </option>
                              </template>
                            </select>
                            <span class="text-danger"> {{ errors[0] }} </span>
                          </ValidationProvider>
                        </div>
                      </div><!-- Country Code -->

                      <!-- Contact Number -->
                      <div class="col-sm-8 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="validation_par_contact_no">{{ cvsParContactNoLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Contact Number" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.parent_contact_no" type="tel" class="form-control" id="validation_par_contact_no" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Contact Number -->

                      <!-- Student Class -->
                      <div class="col-md-12 mb-3">
                        <label for="validation_vmStuGrade">{{ vmChildGradePlaceHolder }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuGrade"
                            :options="vmGrade"
                            track-by="name"
                            label="name"
                            :placeholder="vmChildGradePlaceHolder"
                            :tag-placeholder="vmChildGradePlaceHolder"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Class -->

                      <!-- Student School Name -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validation_name">{{ cvParSchoolNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider
                            name="School Name"
                            rules="required"
                            v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_sch_name" type="text" class="form-control" id="validation_name" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student School Name -->

                      <!-- Student School Board -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validation_vmParCurrPlaceHolder">{{ vmParCurrPlaceHolder }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuCurr"
                            :options="vmCurriculum"
                            track-by="name"
                            label="name"
                            :placeholder="vmParCurrPlaceHolder"
                            :tag-placeholder="vmParCurrPlaceHolder"
                            id="validation_vmParCurrPlaceHolder"
                            required
                            >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student School Board -->

                      <!-- Student Passing Year -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validation_vmStuPassingYear">{{ vmParPassPlaceHolder }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                        <multiselect
                          v-model="vmStuPassingYear"
                          :options="vmStuPass"
                          track-by="name"
                          label="name"
                          :placeholder="vmParPassPlaceHolder"
                          :tag-placeholder="vmParPassPlaceHolder"
                          id="validation_vmStuPassingYear"
                          required
                          >
                        </multiselect>
                        <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Passing Year -->

                      <!-- Student Email -->
                      <div class="col-md-12 mb-3">
                        <label for="validationchild_email">{{ cvChildEmailLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_email" type="text" class="form-control" id="validationchild_email" required />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div><!-- Student Email -->

                      <!-- Student Country Code -->
                      <div class="col-sm-4 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="validationuser_country">{{cvStuCountryCodeLabel}}</label><label class="primary-color font-size-16">*</label>
                        <div class="d-flex flex-row">
                          <ValidationProvider name="Select One" rules="required" v-slot="{ errors }">
                            <select v-model="vmStuParContactUsFormData.user_country" class="form-control w-100" id="validationuser_country">
                              <template class="w-100">
                                <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                  {{country.country_name}} (+ {{country.country_phone_code}})
                                </option>
                              </template>
                            </select>
                            <span class="text-danger"> {{ errors[0] }} </span>
                          </ValidationProvider>
                        </div>
                      </div><!-- Student Country Code -->

                      <!-- Student Contact No -->
                      <div class="col-sm-8 col-md-12 col-lg-6 col-12 mb-3">
                        <label for="validationstu_contact_no">{{ cvChildContactNoLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Contact Number" rules="required" v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.stu_contact_no" type="tel" class="form-control" id="validationstu_contact_no" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Student Contact No -->

                      <!-- Study Country Choice -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validationvmStuChoiceCountry">{{ vmStuChoiceCountry }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Choice" rules="required" v-slot="{ errors }">
                          <multiselect
                            v-model="vmStuPrefferedCountry"
                            :options="vmStuCountryChoice"
                            :multiple="true"
                            track-by="name"
                            label="name"
                            :placeholder="vmStuChoiceCountry"
                            :tag-placeholder="vmStuChoiceCountry"
                            id="validationvmStuChoiceCountry"
                            required
                          >
                          </multiselect>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Study Country Choice -->

                      <!-- Program Choice -->
                      <div class="col-md-12 mb-3" v-if="!module_name.includes('GPaths')">
                        <label for="validation_course_name">{{ cvChildProgramChoiceNameLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider
                            name="Preferred Program for Undergrad. Studies"
                            rules="required"
                            v-slot="{ errors }">
                          <input v-model="vmStuParContactUsFormData.course_name" type="text" class="form-control" id="validation_course_name" required />
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Program Choice -->

                      <!-- Country Where You Live -->
                      <div class="col-md-12 mb-3">
                        <label for="validationstu_country">{{ cvStuLive }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="Select Country" rules="required" v-slot="{ errors }">
                          <select v-model="stuCountry" class="form-control" id="validationstu_country">
                            <template>
                              <option v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country">
                                {{country.country_name}}
                              </option>
                            </template>
                          </select>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- Country Where You Live -->

                      <!-- City Where You Live -->
                      <div class="col-md-12 mb-3">
                        <label for="validationusrl_city">{{ cvStuCityLabel }}</label><label class="primary-color font-size-16">*</label>
                        <ValidationProvider name="City Name" rules="required" v-slot="{ errors }">
                        <input v-model="vmStuParContactUsFormData.stu_city" type="text" class="form-control" id="validationusrl_city" required />
                        <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                      </div><!-- City Where You Live -->
                    </template><!-- Parent Type -->

                    <!-- TnC -->
                    <div class="w-100 col-12" v-if="!showSessions">
                      <input type="checkbox" id="checkbox" v-model="tnc" />&nbsp;&nbsp;<span for="checkbox">I have read and accept the <a href="/content/Tnc" target="_blank">Terms of Use</a></span>
                    </div><!-- TnC -->
                    <br/>

                    <!-- Submit Button -->
                    <div class="form-group float-right w-100 col-12">
                      <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                        <b-spinner label="Spinning"></b-spinner>
                      </button>
                      <button type="button" class="btn btn-primary" style="float:right" v-if="!showSessions" @click="userContactusAdd()">
                        {{ cvSubmitBtn }}
                      </button>
                    </div><!-- Submit Button -->

                    <!-- Social Icons -->
                    <div class="sign-info col-12" v-if="!showSessions && !eventDetail.event_is_paid">
                      <span class="dark-color d-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
                      <ul class="iq-social-media mt-4">
                        <li>
                          <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                        </li>
                        <li>
                          <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                        </li>
                      </ul>
                    </div><!-- Social Icons -->
                  </div><!-- Scroll Bar CSS -->
                </div><!-- form-row m-0 -->
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>

    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToEventPlanList()"/>

    <!-- Payment Modal -->
    <template v-if="showPaymentModal && selectedEventId">
      <b-modal v-model="showPaymentModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true" @hide="emitPaymentStatus(false)">
        <Paygw :propModuleType="modName" :propModuleName="module_name" :propModuleObjId="selectedEventId" :propSrc="SRC" @emitPaymentStatus="emitPaymentStatus(true)" :propEventMktSrc="source" :propRedirect="'confirmation?code=PAYMENT_DONE'" :propUserId="userId"/>
          <template #modal-footer="">
            <b-button @click="emitPaymentStatus(false)" size="sm" class="pull-left">Close</b-button>
          </template>
      </b-modal>
    </template><!-- Payment Modal -->

  </b-container>
</template>

<script>
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import Multiselect from "vue-multiselect"
import { Countries } from "../../../FackApi/api/country.js"
import { events } from "../../../FackApi/api/events.js"
import { analyticsTracker } from "../../../FackApi/api/analytics/analytics_tracker"
import { v4 as uuidv4 } from "uuid"
import Paygw from "../../../views/Gide/Paygw/Paygw.vue"
import AlertBox from "../../../components/AlertBox.vue"
import { SendMail } from "../../../FackApi/api/sendMail"

export default {
  name: "ContactUs",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider,
    Multiselect,
    AlertBox,
    Paygw
  },
  data () {
    return {
      cvCardTitle: "Contact Us",
      cvCardSubHeader: "Are you a Student/Parent",
      cvSubmitBtn: "Submit",
      cvStuParTypeLabel: "Are You Parent / Student ?",
      cvGender: "Select Your Gender",
      vmGradePlaceHolder: "Your Grade/Class",
      vmChildGradePlaceHolder: "Your Child's Grade/Class",
      vmStuCurrPlaceHolder: "Select Education Board",
      vmParCurrPlaceHolder: "Select Your Child's School Board",
      vmStuPassPlaceHolder: "Select Your Passing Year",
      vmParPassPlaceHolder: "Select Your Child's Passing Year",
      cvProgramChoiceNameLabel: "Preferred Program for Undergrad. Studies",
      cvChildProgramChoiceNameLabel: "Preferred Program for Undergrad. Studies",
      vmStuChoiceCountry: "Preferred country for Undergrad",
      cvStuNameLabel: "What is Your First name ?",
      cvStuLastNameLabel: "What is Your Last Name ?",
      cvStuSchoolNameLabel: "School Name",
      cvParSchoolNameLabel: "Child's School Name",
      csStuEmailLabel: "Your Email Id",
      vsParEmailLabel: "Your Email Id",
      cvParEmailLabel: "Parent's Email Id",
      cvChildEmailLabel: "Child's Email Id",
      cvStuContactNoLabel: "Contact no",
      cvsParContactNoLabel: "Contact no",
      cvParContactNoLabel: "Parent's No.",
      cvChildContactNoLabel: "Child's Contact no",
      cvStuCityLabel: "Which city do you live in ?",
      cvStuCountryCodeLabel: "Country code",
      cvStuLive: "Which country do you live in ?",
      eventIsPaidMsg: "Please check your mailbox for email verification OTP. This activity requires a payment to be made. Kindly click on 'Proceed' button to complete the payment",
      eventSubmittedPaymentCancelledMsg: `Thank you for registering on GIDE.AI. Please check your email for the One-Time Password (OTP) to Proceed. If you don't see it, remember to check your Spam folder. Need help? Contact support@gide.ai`,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Contact Us",
      vmStuParContactUsFormData: {},
      vmUserRegLeadDesc: null,
      vmUsertype: { name: "Student" },
      vmUserGender: {},
      vmStuGrade: {},
      cvCountryOptions: [],
      vmStuPassingYear: {},
      vmStuPrefferedCountry: [],
      vmStuCurr: {},
      tnc: false,
      stuCountry: null,
      source: "",
      preferredCountryArr: [],
      module_obj_id: null,
      module_name: "", // Event Name, UNIV Name
      module_type: null, // EVENT, UNIV, ORG etc
      vmStudentParentSelectOptions: [
        { name: "Student" },
        { name: "Parent" }
      ],
      vmGender: [
        { name: "Male" },
        { name: "Female" },
        { name: "Others" }
      ],
      vmGrade: [
        { name: "9" },
        { name: "10" },
        { name: "11" },
        { name: "12" },
        { name: "Others" }
      ],
      vmCurriculum: [
        { name: "CBSE" },
        { name: "ICSE" },
        { name: "ISC" },
        { name: "IGCSE" },
        { name: "IB" },
        { name: "INTERNATIONAL" },
        { name: "STATE BOARD" },
        { name: "OTHER" }
      ],
      vmStuPass: [
        { name: "2024" },
        { name: "2025" },
        { name: "2026" },
        { name: "2027" },
        { name: "2028" },
        { name: "2029" },
        { name: "2030" },
        { name: "2031" },
        { name: "Others" }
      ],
      vmStuCountryChoice: [
        { name: "Australia" },
        { name: "Canada" },
        { name: "Europe" },
        { name: "Hong Kong" },
        { name: "India" },
        { name: "Ireland" },
        { name: "New Zealand" },
        { name: "Singapore" },
        { name: "United Kingdom" },
        { name: "United States of America" },
        { name: "Others" }
      ],
      userId: "",
      showUpgradeAlertBox: false,
      alertTitle: "Events",
      alertDesc: "Please upgrade to Gide Plus",
      okText: "Upgrade",
      cancelText: "Not now",
      showPaymentModal: false,
      selectedEventId: null,
      eventDetail: {},
      operatingsystem: window.__DEVICE_OS__,
      timeZoneOffset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      SRC: "contactUs",
      modName: "event", // Module name
      spcmObj: {},
      showSessions: false
    }
  },
  computed: {
    sourceData () {
      return this.$store.getters["SourceTracker/getUserSource"]
    },
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  async mounted () {
    // if sourceData is available then store in source else take user defined value to store in source
    this.source = this.$route.query.source ? this.$route.query.source : this.sourceData

    this.countryDistinctList()

    // When  we are opening this page from event participation report or any other place then we need to show name as Title of form
    if (this.$route.query.module_name) {
      this.module_name = this.$route.query.module_name
      this.cvCardTitle = this.$route.query.module_name + " - Register Here"
    }

    if (this.$route.query.module_id) {
      this.module_obj_id = this.$route.query.module_id
      if (this.module_obj_id.includes("EVE") || this.module_obj_id.includes("APP")) {
        this.getEventDetail()
      }
    }

    if (this.$route.query.module_type) {
      this.module_type = this.$route.query.module_type
    }

    if (this.userData.user_id) {
      this.userId = this.userData.user_id
    }
    else {
      this.userId = "cfuser" + "_" + uuidv4().replace(/-/g, "") // cf is contact us form
    }

    let title = this.module_obj_id // this.cvCardTitle.substring(0, 40).replace(/([^\w]+|\s+)/g, "").toLowerCase()
    window.fbq("trackCustom", (title + "_init"))
    analyticsTracker.analyticsTrackerAdd(this, { module_name: this.cvCardTitle, action: "init", source: this.source, user_id: this.userId })
  },
  methods: {
    /**
     * getEventDetail
     */
    async getEventDetail () {
      const resp = await events.eventViewPublic(this, this.module_obj_id)
      if (resp.resp_status) { this.eventDetail = resp.resp_data.data }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        if (this.timeZoneOffset && this.timeZoneOffset.includes("Asia")) {
          let findIndex = this.cvCountryOptions.findIndex(ele => ele.country_name.toLowerCase() === "india")
          if (findIndex >= 0) {
            this.vmStuParContactUsFormData.user_country = this.cvCountryOptions[findIndex]
            this.vmStuParContactUsFormData.parent_country = this.cvCountryOptions[findIndex]
            this.stuCountry = this.cvCountryOptions[findIndex]
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmStuParContactUsFormData) {
          if (!this.vmStuParContactUsFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * userContactusAdd
     */
    async userContactusAdd () {
      let valid = await this.$refs.pbaForm.validate()
      if (!valid) {
        return
      }

      if (!this.tnc) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "You have not accepted the terms & conditions"
        return
      }

      this.vmStuParContactUsFormData.stu_email = this.vmStuParContactUsFormData.stu_email.trim()
      this.vmStuParContactUsFormData.parent_email = this.vmStuParContactUsFormData.parent_email.trim()

      let tempVmStuParContactUsFormData = { ...this.vmStuParContactUsFormData } // because we are changing data below so it should'nt affect the field like in mobile num it apeending country code multiple times

      tempVmStuParContactUsFormData.user_type = this.vmUsertype.name
      tempVmStuParContactUsFormData.vmUserGender = this.vmUserGender.name
      tempVmStuParContactUsFormData.vmStuGrade = this.vmStuGrade.name
      tempVmStuParContactUsFormData.vmStuCurr = this.vmStuCurr.name
      tempVmStuParContactUsFormData.vmStuPassingYear = this.vmStuPassingYear.name
      tempVmStuParContactUsFormData.stu_contact_no = this.vmStuParContactUsFormData.user_country.country_phone_code + this.vmStuParContactUsFormData.stu_contact_no
      tempVmStuParContactUsFormData.parent_contact_no = this.vmStuParContactUsFormData.parent_country.country_phone_code + this.vmStuParContactUsFormData.parent_contact_no
      tempVmStuParContactUsFormData.stu_country = this.stuCountry.country_name
      tempVmStuParContactUsFormData.source = this.source
      tempVmStuParContactUsFormData.userId = this.userId

      for (let i = 0; i < this.vmStuPrefferedCountry.length; i++) {
        this.preferredCountryArr.push(this.vmStuPrefferedCountry[i].name)
      }

      tempVmStuParContactUsFormData.vmStuPrefferedCountry = this.preferredCountryArr

      try {
        this.cvLoadingStatus = true

        // In source we pass eventID
        if (this.module_obj_id) {
          // if event id found then insert data in rsvp and contact_lead_form table
          tempVmStuParContactUsFormData.module_obj_id = this.module_obj_id
          tempVmStuParContactUsFormData.event_id = this.module_obj_id
          tempVmStuParContactUsFormData.module_name = this.module_name
          tempVmStuParContactUsFormData.module_type = this.module_type
        }

        let response = await events.eventUserRegistrationViaContactUsForm(this, tempVmStuParContactUsFormData)
        if (response && !response.resp_status) {
          // User Exists
          this.showToast = true
          this.toastVariant = "danger"
          this.toastMsg = response.resp_msg
          return
        }
        else {
          this.userId = response.resp_data.user_id

          if (this.eventDetail.event_hosting_platform == "DOWNLOAD_LINK" && this.eventDetail.event_weblink) {
            // Redirect to the Download Link without any further processing
            window.open(this.eventDetail.event_weblink, "_blank")
            return
          }

          if (response.user_exists) {
            // If user is registered

            if (this.module_name.includes("GPaths")) {
              // for GPATHS
              this.$store.dispatch("DeepLink/setDeepLinkAction", "/gpaths")
            }
            else {
              // For other events
              await this.$store.dispatch("DeepLink/setDeepLinkAction", `/event/${this.module_obj_id}`)
              if (Object.keys(this.userData).length < 1) {
                this.$router.push(`/auth/login`)
              }
              else {
                this.$router.push(`/event/${this.module_obj_id}`)
              }
            }
          }
          else {
            // If user is not registered on GIDE
            if (this.module_name.includes("GPaths")) {
              // If the module_name is GPATHS, move to email verification through otp
              this.$store.dispatch("DeepLink/setDeepLinkAction", "/gpaths")
            }
            else {
              this.$store.dispatch("DeepLink/setDeepLinkAction", `/event/${this.module_obj_id}`)
            }

            // Set the userData only if user is not already logged in
            if (this.userData && this.userData.user_email != this.vmStuParContactUsFormData.stu_email) {
              this.$store.dispatch("User/setUserProfileAction", { user_email: this.vmStuParContactUsFormData.stu_email })
            }
          }

          ApiResponse.responseMessageDisplay(this, response)
        }

        if (response.resp_status) {
          let title = this.module_obj_id // this.cvCardTitle.substring(0, 40).replace(/([^\w]+|\s+)/g, "").toLowerCase()
          window.fbq("trackCustom", (title + "_complete"))
          analyticsTracker.analyticsTrackerAdd(this, { module_name: this.cvCardTitle, action: "complete", source: this.source, user_id: response.resp_data.user_id ? response.resp_data.user_id : this.userId })

          if (response.resp_data.isPaidEvent == true && !response.resp_data.isUserSub == true) {
            this.vmStuParContactUsFormData.user_id = response.resp_data.user_id
            this.startPaymentForStuEvent(this.vmStuParContactUsFormData)
          }

          // We want to clear it to avoid Misuse
          this.$refs.pbaForm.reset()
          this.preferredCountryArr = []
          this.vmStuParContactUsFormData = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at userContactusAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * goToEventPlanList
     */
    goToEventPlanList () {
      this.$router.push("/subscription_plans")
    },
    /**
     * emitPaymentStatus
    */
    async emitPaymentStatus (event) {
      this.selectedEventId = null
      this.showPaymentModal = false

      if (event == false) {
        // Payment Box has been closed. So send OTP to user
        let userData = {
          user_id: this.vmStuParContactUsFormData.user_id,
          subject: "login_otp"
        }

        let sendMailResponse = await SendMail.sendMailToUser(this, userData)

        const options = {
          title: "Your form was submitted successfully",
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "Verify OTP",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        }

        let value = await this.$bvModal.msgBoxConfirm(this.eventSubmittedPaymentCancelledMsg, options)
        if (value) {
          if (!sendMailResponse.resp_status && sendMailResponse.resp_code == "ERR_USER_VERIFIED_OK") {
            this.showToast = true
            this.toastVariant = "success"
            this.toastMsg = "Congratulation, you are a verified user. Please proceed to login"

            setTimeout(() => {
              this.$router.push(`/auth/login`)
            }, 3000)
          }
          else {
            this.$router.push(`/auth/activate`)
          }
        }
        else {
          this.$router.push(`/auth/login`)
        }
      }
    },
    /**
     * starting payment
     */
    async startPaymentForStuEvent (stuFormData) {
      if (this.userData && this.userData.user_is_logged_in != 1) {
        let userData = {
          user_name: stuFormData.stu_first_name,
          user_email: stuFormData.stu_email,
          user_id: stuFormData.user_id,
          user_mobile: stuFormData.stu_contact_no,
          user_role: "USERROLE11114",
          type: "STUDENT"
        }

        this.$store.dispatch("User/setUserProfileAction", userData)
      }
      this.selectedEventId = this.module_obj_id

      /*
      const options = {
        title: "Your form was submitted successfully",
        size: "md",
        buttonSize: "md",
        okVariant: "primary",
        okTitle: "Proceed",
        cancelTitle: "Not Now",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      }

      const value = await this.$bvModal.msgBoxConfirm(this.eventIsPaidMsg, options)
      if (value) {
        this.showPaymentModal = true
      }
      */

      this.showPaymentModal = true
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  @import url("../../../assets/css/custom.css");
  @import url("../../../assets/css/PriceSlider.css");
  .student_parent_gide {
    .text-danger {
      color: #dc3545 !important;
    }
    .sign-info{
      margin-top:0px;
      border: 0px;
      padding-top:30px;
    }
    .student_parent_subhead{
      color: var(--iq-body-text);
      font-size:14px ;
    }
    .form-control {
      height: 45px;
      line-height: 45px;
      background: transparent;
      border: 1px solid #f1f1f1;
      font-size: 14px;
      color: var(--iq-secondary);
      border-radius: 10px;
    }
    .lead_add_form{
      .table thead th{
        border-bottom:0px ;
      }
      .card-title{
        color:var(--iq-title-text);
      }
      label{
        color: var(--iq-body-text)
      }
      .iq-card{
        margin: 0px;
        border: none;
        box-shadow: none;
        .iq-card-header{
          padding: 0px;
          border: 0px;
        }
        .iq-card-body{
          padding: 0px;
        }
      }
    }
  }
  @media screen and (min-width:576px){
    .gide_scroll_box{
      overflow: auto;
      height: calc(100vh - 320px);
      max-height: 550px;
    }
    .gide_box{
      overflow: auto;
      height: calc(100vh - 320px);
      max-height: 550px;
    }
  }
  textarea {
    min-height: 150px;
    line-height: 27px !important;
  }
  .event_data{
    .gide_scroll_box{
        max-height: 400px;
    }
  }
</style>
